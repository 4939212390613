@import "../../styles/scss/break_point.scss";
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba($color: $black, $alpha: 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  .conteiner {
    position: relative;
    width: 70rem;
    margin: 1.5rem;
    background-color: $white;
    border-radius: 10px;
    box-shadow: $shadow-small;
    @media (max-width: $layout-breakpoint-SCREEN_XL) {
      width: 60rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_MD) {
      width: 50rem;
      border-radius: 5px;
      margin: 1rem;
    }
    .popup_body {
      box-sizing: content-box;
      position: relative;

      margin: 2.8rem 2rem;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      @media (max-width: $layout-breakpoint-SCREEN_MD) {
        margin: 2.3rem 1.5rem;
      }
      @media (max-width: $layout-breakpoint-SCREEN_SSM) {
        margin: 1.7rem 1rem;
      }
    }
  }
}
.button_exit {
  position: absolute;
  top: 0;
  right: 0;

  svg {
    font-size: 3rem;
    transition: all 0.5s ease-out;
    &:hover {
      transform: rotate(180deg);
    }
    @media (max-width: $layout-breakpoint-SCREEN_XL) {
      font-size: 2.5rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_MD) {
      font-size: 2rem;
    }
  }
}
.message {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.8rem;
  @media (max-width: $layout-breakpoint-SCREEN_XL) {
    gap: 1.4rem;
  }
  @media (max-width: $layout-breakpoint-SCREEN_MD) {
    gap: 1rem;
  }
  @media (max-width: $layout-breakpoint-SCREEN_SSM) {
    font-size: 0.7rem;
  }
  .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .subtitle {
    font-size: 2.2rem;
    @media (max-width: $layout-breakpoint-SCREEN_XL) {
      font-size: 1.8rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_MD) {
      font-size: 1.6rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_SSM) {
      font-size: 1.4rem;
    }
  }
  .title {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 3rem;
    @media (max-width: $layout-breakpoint-SCREEN_XL) {
      font-size: 2.6rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_MD) {
      font-size: 2.2rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_SSM) {
      font-size: 1.8rem;
    }
  }
}

// Breakpoints
$layout-breakpoint-SCREEN_SSSM: 300px;
$layout-breakpoint-SCREEN_SSM: 400px;
$layout-breakpoint-SCREEN_SM: 576px;
$layout-breakpoint-SCREEN_MD: 768px;
$layout-breakpoint-SCREEN_LG: 992px;
$layout-breakpoint-SCREEN_XL: 1200px;
$layout-breakpoint-SCREEN_XXL: 1500px;

// Colors
$background: #d9d9d9;
$white: #fff;
$black: #24242a;
$red: #c21f39;

// other
$shadow-midle: 0 4px 8px rgba(0, 0, 0, 0.1), 0 16px 32px rgba(0, 0, 0, 0.1);
$shadow-small: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --scrolledY: 0;
}
html {
  font-size: 62.5%;
  line-height: 1.25;
  overflow: auto;
}
body {
  height: 100%;
  color: rgb(0, 0, 0);
  font-family: "Montserrat", sans-serif;
  width: 100%;
}
body.active_body {
  position: fixed;
  top: calc(var(--scrolledY) * -1px);
}
._cont_limit {
  max-width: 1780px;
  padding: 0px 15px;
  margin: 0px auto;
  height: 100%;
  box-sizing: content-box;
}
.main {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
}

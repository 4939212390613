@import "../../styles/scss/break_point.scss";
header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 666;
  background-color: white;
  transition: 0.2s linear;
  height: 7rem;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
  @media (max-width: $layout-breakpoint-SCREEN_XL) {
    height: 6.2rem;
  }
  @media (max-width: $layout-breakpoint-SCREEN_MD) {
    height: 5.5rem;
  }

  .heder_body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
}
.logo {
  display: flex;
  align-items: center;

  img {
    height: 4.8rem;
    margin-right: 0.5rem;
    margin-top: -0.8rem;
    @media (max-width: $layout-breakpoint-SCREEN_XL) {
      height: 4.2rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_MD) {
      height: 3.8rem;
    }
  }
  span {
    font-size: 4rem;
    letter-spacing: -0.05em;
    font-weight: 500;
    color: #c21f39;
    @media (max-width: $layout-breakpoint-SCREEN_XL) {
      font-size: 3.5rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_MD) {
      font-size: 3rem;
    }
  }
}
.menu_list {
  display: flex;

  a {
    position: relative;
    font-size: 2.6rem;
    margin-left: 2.5em;
    font-weight: 500;
    transition: all 0.1s;
    @media (max-width: $layout-breakpoint-SCREEN_XL) {
      font-size: 2.2rem;
      margin-left: 2em;
    }
    @media (max-width: $layout-breakpoint-SCREEN_MD) {
      font-size: 1.8rem;
      margin-left: 1.5em;
    }
    &::after {
      content: " ";
      width: 0%;
      height: 0.12em;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #000;
      transition: width 0.4s;
    }
    &:hover::after {
      width: 100%;
    }
  }
}

@import "../../../styles/scss/break_point.scss";

.loader {
  width: 100%;
  height: 100svh;
  position: relative;
  overflow: hidden;
  @media (max-width: $layout-breakpoint-SCREEN_LG) {
    height: 80svh;
  }
  @media (max-width: $layout-breakpoint-SCREEN_MD) {
    height: 75svh;
  }
  @media (max-height: 600px) {
    height: 100svh;
  }
  .loader_bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: url("../../../../public/images/bg.jpg") no-repeat left top;
    background-size: cover;
  }
  .loader_body {
    padding: 11rem 0rem 16rem 0rem;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    @media (max-width: $layout-breakpoint-SCREEN_XXL) {
      padding: 9rem 0rem 10rem 0rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_LG) {
      padding: 9rem 0rem 8rem 0rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_SM) {
      padding: 7rem 0rem 4rem 0rem;
    }
    @media (max-height: 600px) {
      padding: 7rem 0rem 3rem 0rem;
    }

    .loader_column {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .row_block {
    overflow: hidden;
    flex-wrap: wrap;
    span {
      position: relative;
      display: inline-block;
      white-space: nowrap;
      text-transform: uppercase;
    }
  }
}

.row_bot {
  display: flex;
  img {
    height: 14rem;
    @media (max-width: 1650px) {
      height: 12.5rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_LG) {
      height: 11rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_MD) {
      height: 9rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_SM) {
      height: 8rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_SSM) {
      height: 6rem;
    }
    @media (max-height: 600px) {
      height: 9rem;
    }
  }
  .row_block {
    padding-right: 2rem;
    color: #c21f39;
  }
  .blok_logo {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $layout-breakpoint-SCREEN_SM) {
      margin-right: 0rem;
    }
  }
}
.row_title {
  font-size: 11rem;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: -0.05em;
  @media (max-width: 1650px) {
    font-size: 9.5rem;
  }

  @media (max-width: $layout-breakpoint-SCREEN_LG) {
    font-size: 8.5rem;
  }
  @media (max-width: $layout-breakpoint-SCREEN_MD) {
    font-size: 7rem;
  }
  @media (max-width: $layout-breakpoint-SCREEN_SM) {
    font-size: 6.5rem;
  }
  @media (max-width: $layout-breakpoint-SCREEN_SSM) {
    font-size: 5rem;
  }
  @media (max-height: 600px) {
    font-size: 7rem;
  }
}
.block_subtitle {
  .row_subtitle {
    font-size: 3rem;
    line-height: 1.5;
    font-weight: 500;
    @media (max-width: 1650px) {
      font-size: 2.5rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_XXL) {
      font-size: 2.2rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_LG) {
      font-size: 2rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_SM) {
      font-size: 1.8rem;
    }
    @media (max-width: $layout-breakpoint-SCREEN_SSM) {
      font-size: 1.6rem;
    }
    @media (max-height: 600px) {
      font-size: 1.8rem;
    }

    // @media (max-width: $layout-breakpoint-SCREEN_LG) {
    //   font-size: 1.8rem;
    // }
  }
}

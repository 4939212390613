@import "../../../styles/scss/break_point.scss";
.table_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 600;
  color: $white;
  button {
    font-size: 2.5rem;
    font-weight: 500;
    padding: 0.4em 1em;
    border: 1px solid $black;
    border-radius: 0.3em;
    background-color: $black;
    color: $white;
    transition: all 0.2s ease;
    &:hover {
      background-color: $white;
      color: $black;
      box-shadow: $shadow-small;
    }
  }
}
.conteiner_vacansii {
  position: relative;
  width: 100%;
  border: 1px solid rgba($color: $black, $alpha: 0.5);
  border-radius: 10px;
  max-height: 100rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: $white;
  &:hover {
    box-shadow: $shadow-midle;
  }
  .messenge_clear {
    text-align: center;
    margin: 1em 0em;
    font-size: 2rem;
    font-weight: 600;
    color: $red;
  }
  .row {
    position: relative;
    display: grid;
    grid-template-columns: 0.5fr 0.6fr 1fr 1fr 0.7fr 2.2fr 2.2fr 0.7fr;
    height: 100%;
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.03);
    }
    &:not(:last-child) {
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.5);
    }

    .item {
      display: flex;
      font-size: 1.4rem;
      width: 100%;
      padding: 1rem 0.8rem;
      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.06);
      }
      &:not(:last-child) {
        border-right: 1px solid rgba($color: $black, $alpha: 0.2);
      }
      &.center {
        justify-content: center;
        &:last-child {
          flex-direction: column;
          align-items: center;
          gap: 2rem;
        }
      }

      button {
        font-size: 1.4rem;
        font-weight: 500;
        width: 7em;
        padding: 0.2em 0em;
        border: 1px solid $black;
        border-radius: 0.3em;
        background-color: $white;
        transition: all 0.2s ease;
        &:hover {
          background-color: $black;
          color: $white;
          box-shadow: $shadow-small;
        }
      }
    }
    &.row_header {
      font-size: 1.6rem;
      font-weight: 500;
      min-height: 5rem;
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
